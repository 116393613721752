<template>
  <div class="main-content" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div v-if="liveList.length > 0" class="travl-body">
      <div v-if="timeType===1" class="travl-head">
        <span>{{ liveList[0].name === '报到' ? 'AM' : 'AM' }}</span>{{liveList[0].startDate}}
      </div>
      <div v-if="timeType===2" class="travl-head pm">
        <span>PM</span>{{liveList[0].startDate}}
      </div>
      <div class="travl-container"
        v-for="item in liveList"
        :key="item.id"
      >
        <div :class="{ 'travl-item': true }" @click="changeRouter(item)">
          <div class="title">
            {{item.name}}
          </div>
          <!-- <div class="replay" @click.stop="viewLive(item.miceName)">
            回放
            <van-icon style="margin-left:5px" name="play-circle-o" />
          </div> -->
          <!-- <div class="content">
            <span>主题：{{ getTopicByName(item.name) }}</span>
          </div> -->
          <!-- <div v-if="item.name === '报到'" class="content">
            <van-icon class="icon" name="underway-o" />
            07-14 全天
          </div>
          <div v-else class="content">
            <van-icon class="icon" name="underway-o" />
            {{ item.startTime.substr(0, 5) }}-{{ item.endTime.substr(0, 5) }}
          </div> -->
          <div class="content"> 
            <van-icon class="icon" name="location-o" />
            <span v-if="item.name === '报到'" v-html="item.address"></span>
            <span v-else>{{ item.address }}</span>
          </div>

         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  name: 'vipStroke',
  props: {
    liveList: {
      type: Array
    },
    timeType: {
      type: Number
    },
    activeDay: {
      type: Number
    }
  },
  data() {
    return {
      list: [],
      contentList: [],
      activeName: [],
      colorList: {
        '用餐': '#ff5000',
        '活动': '#ffbe00',
        '酒店': '#63BCF8',
        '交通': '#43CF43'
      },
      labelList: {
        '用餐': require('@/assets/images/vipjourney/meal.png'),
        '活动': require('@/assets/images/vipjourney/important.png'),
        '酒店': require('@/assets/images/vipjourney/hotel.png'),
        '交通': require('@/assets/images/vipjourney/traffic.png')
      },
      menuList: [
        {
          type: '全部',
          icon: require('@/assets/images/vipjourney/menu-all.png')
        },
        {
          type: '用餐',
          icon: require('@/assets/images/vipjourney/menu-meal.png')
        },
        {
          type: '活动',
          icon: require('@/assets/images/vipjourney/menu-important.png')
        },
        {
          type: '酒店',
          icon: require('@/assets/images/vipjourney/menu-hotel.png')
        },
        {
          type: '交通',
          icon: require('@/assets/images/vipjourney/menu-traffic.png')
        }
      ],
      topicData: {
        "岳麓论坛":"岳麓论坛",
        "峰会新十年  湘商新征程": "峰会新十年  湘商新征程",
        "文化智核  科技新篇": "文化智核  科技新篇",
        "投资新趋势 产业新格局": "投资新趋势 产业新格局",
        "产业互联网 智汇长沙城": "融合创新·共筑产业互联新生态",
        "AI驱动 数实融合": "AI驱动 数实融合",
        "开幕式|岳麓论坛": "AI汇湘江 数智驱未来"
      },
      showMenu: false,
      active: 0
    }
  },
  filters: {
    dateFilter(date) {
      return `${date.split('-')[0]}年${date.split('-')[1]}月${date.split('-')[2]}日`
    }
  },
  methods: {
    getTopicByName(name) {
      return this.topicData[name]
    },
    viewLive(link) {
      window.location.href = link
    },
    changeRouter(item) {
      // if(item.name === '开幕式|岳麓论坛') {
      //   Toast('敬请期待')
      //   return
      // }
      if (!item.groupLink) {
        return
      }
      // if (item.linkUrl === '' || item.linkUrl === 'show-test-none') {
      //   Toast({
      //     message: '敬请期待',
      //     position: 'middle'
      //   })
      //   this.$nextTick(() => {
      //     document.querySelector('.van-toast').style.background = 'rgb(255,255,255)'
      //     document.querySelector('.van-toast').style.color = '#333'
      //     document.querySelector('.van-toast').style.boxShadow = '0 0 5px 1px rgba(0,0,0,.05)'
      //   })
      //   return
      // }

      this.$store.commit('groupInfo/SET_GROUP', item)
      console.log(item)
      this.$router.push('/conference/' + this.$store.state.miceInfo.miceLink + '/' + item.groupLink)      
    }
  }
}
</script>
<style scoped lang="less">
@themeColor: var(--themeColor);
@blue_color: #5f52a0;
@green_color: #0068b7;
.main-content{
  // height: 100%;
  margin-bottom: 20px;
}
.travl-body {
  padding: 20px 20px 10px;
  background: #fff;
  border-radius: 5px;
}
.travl-head {
  margin-bottom: 10px;
  color: #2ab492;
  font-size: 12px;
  font-weight: bold;
  span {
    margin-right: 6px;
  }
  &.pm {
    color: #2ab492;
  }
}
.travl-container{
  // padding: 0 0 10px 0;
  position: relative;
  // &::before{
  //   content: '';
  //   position: absolute;
  //   display: inline-block;
  //   height: 9PX;
  //   width: 9PX;
  //   border-radius: 50%;
  //   left: -4PX;
  //   top: 0;
  //   z-index: 1;
  //   background: @blue_color;
  // }
  // &::after{
  //   content: '';
  //   position: absolute;
  //   display: inline-block;
  //   border-left: 1px dashed #ccc;
  //   height: calc(100% - 10px);
  //   left: 0;
  //   top: 0;
  //   z-index: 0;
  // }
  
  .travl-date{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .travl-item{
    position: relative;
    background: #fff;
    padding: 20px 0;
    border-top: 1px solid #f4f4f8;
    // border-top-right-radius: 5px;
    // border-bottom-right-radius: 5px;
    // box-shadow: 0 0 1px 1px rgba(0, 0, 0, .05);
    font-size: 15px;
    .replay{
      position: absolute;
      top: 15px;
      right: -15px;
      height: 23px;
      padding: 0 5px;
      font-size: 11px;
      display: flex;
      align-items: center;
      background: @themeColor;
      color: #fff;
      opacity: .7;
      border-radius: 5px;
    }
    // border-left: 3px solid @blue_color;
    .title {
      margin-bottom: 15px;
      color: #333;
      width: 80%;
    }
    .content{
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #a1a1a1;
      margin-bottom: 10px;
    }
    .icon{
      margin-right: 10px;
    }
  }
  .last-item{
    &::after{
      border-left: none;
    }
  }
  &:nth-child(odd) {
    &::before{
      background: @blue_color;
    }
    .travl-item {
      // border-left: 3px solid @green_color;
    }
  }
}
.scale-enter,
.scale-leave-to{
  opacity: 0;
  transform: scale(0);
}
.dropdown-enter,
.dropdown-leave-to{
  right: -200%;
  opacity: 0;
}
.dropdown-enter-active,
.dropdown-leave-active{
  transition: .3s;
}
</style>

