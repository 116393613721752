<template>
  <div class="arrange-container">
    <div class="arrange-head">
      <Banner />
      <div class="banner-bottom" ref="bannerBottom">
        <canvas id="banner-bg"></canvas>
      </div>
      <div class="date-wrapper">
        <div
          v-for="item in dateList"
          :key="item.value"
          class="date-item"
          @click="handleActiveDay(item)"
          :class="{ 'date-item_active': active_day === item.value }"
        >
          <span>{{ item.label }}</span>
          <span>{{ item.week | weekFilterEn }}</span>
        </div>
      </div>
    </div>

    <div v-if="pageOk" class="arrange-list">
      <journey-list :timeType="1" :liveList="dateList[active_day - 1].amList" key="1" :activeDay="active_day" />
      <journey-list :timeType="2" :liveList="dateList[active_day - 1].pmList" key="2" :activeDay="active_day" />
      <van-empty
        description="暂无数据"
        v-if="dateList[active_day - 1].amList.length === 0 && dateList[active_day - 1].pmList.length === 0"
      ></van-empty>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import Banner from "@/components/banner/index";
import journeyList from "./components/journeyList";
export default {
  components: {
    journeyList,
    Banner
  },
  data() {
    return {
      liveList: [],
      loading: false,
      swiper: {},
      amList: [],
      pmList: [],
      dateList: [],
      active_day: 1,
      pageOk: false
    };
  },
  filters: {
    weekFilterEn(val) {
      switch (val) {
        case 1:
          return "Mon";
        case 2:
          return "Tue";
        case 3:
          return "Wed";
        case 4:
          return "Thu";
        case 5:
          return "Fri";
        case 6:
          return "Sat";
        case 0:
          return "Sun";
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("activeDay")) {
      this.active_day = JSON.parse(sessionStorage.getItem("activeDay"));
    }
    this.getLiveList();
    this.$nextTick(() => {
      this.draw();
    });
    // window.onresize = (() => {
    //   this.draw()
    // })
  },
  activated() {
    console.log(2222);
  },
  methods: {
    handleActiveDay(data) {
      this.active_day = data.value;
    },
    async getLiveList() {
      this.loading = true;
      let result = await this.$http.get(
        this.$baseURL.index.getGroupList() + "?miceLink=" + this.$store.state.miceInfo.miceLink,
        1
      );
      this.loading = false;

      if (result.code === 200) {
        let dateArray = [];
        result.data.forEach((item, index) => {
          dateArray.push(item.startDate);
          if (result.data[index].addressInfo) {
            result.data[index].address = JSON.parse(result.data[index].addressInfo).place;
          }
        });
        // 去重的日期列表
        dateArray = this.$utils.uniqueArray(dateArray);
        dateArray.forEach((item, index) => {
          this.dateList.push({
            value: index + 1,
            label: item.substr(8, 2),
            week: this.$moment(item).day(),
            amList: [],
            pmList: []
          });
        });
        // am pm 分类
        result.data.forEach(item => {
          this.dateList.forEach(element => {
            if (item.display === 1 && item.startDate.substring(item.startDate.length - 2) === element.label) {
              const hh = parseInt(item.startTime.substr(0, 2));
              if (hh <= 12) {
                element.amList.push(item);
              } else {
                element.pmList.push(item);
              }
            }
          });
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
      this.pageOk = true;
    },
    draw() {
      const canvas = document.getElementById("banner-bg");
      if (!canvas) {
        return;
      }
      const ctx = canvas.getContext("2d");
      const h = this.$refs.bannerBottom.clientHeight;
      const w = this.$refs.bannerBottom.clientWidth;
      canvas.height = h;
      canvas.width = w;
      ctx.beginPath();
      // 线的颜色， 线的粗细
      ctx.fillStyle = "#f2f8fc";
      ctx.lineWidth = 1;
      // 起始点
      ctx.moveTo(0, h / 2); //（从上一点）
      ctx.bezierCurveTo(w / 4, 0, (3 * w) / 4, h, w, h / 2);

      ctx.lineTo(w, h);
      ctx.lineTo(0, h);
      ctx.fill();
    }
  }
};
</script>
<style lang="less" scoped>
.arrange-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f2f8fc;
  padding-bottom: 55px;
  .arrange-head {
    position: relative;
    height: 180px;
  }
  .banner-bottom {
    position: absolute;
    bottom: -10px;
    height: 60px;
    width: 100%;
    z-index: 999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .date-wrapper {
    position: absolute;
    bottom: -25px;
    left: 20px;
    width: 100%;
    color: #214bf5;
    display: flex;
    z-index: 999;
    font-size: 8px;
    .date-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      text-align: center;
      span:nth-of-type(1) {
        margin-top: 2px;
        font-size: 14px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        display: inline-block;
      }
      &.date-item_active {
        span:nth-of-type(1) {
          background: #214bf5;
          font-weight: bolder;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
}
.arrange-list {
  margin-top: 30px;
  flex: 1;
  padding: 5px 15px 0;
  overflow-y: auto;
  padding-bottom: 55px;
}
.item {
  width: 100%;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  .date {
    height: 35px;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    line-height: 35px;
    font-size: 15px;
    padding: 0 5px;
    background: #8bcbf8;
    color: #fff;
  }
  .wrap {
    display: flex;
    width: 100%;
    padding: 10px;
    // justify-content: center;
    align-items: center;
    .left {
      width: 75%;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        // padding: 5px;
        width: 100%;
        white-space: nowrap;
      }
    }
    .right {
      font-size: 12px;
      width: 25%;
      color: #fff;
      display: inline-block;
      text-align: center;
      padding: 8px 15px;
      border-radius: 15px;
      background: #29a2f8;
    }
  }
}
</style>
